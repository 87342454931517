import { ActivityLogger } from './activity-logger';


const logger = new ActivityLogger({
    ajaxUrlPageview: "/activity/pageview",
    ajaxUrlEvent: '',
    ajaxUrlLink: '',
});
ActivityLogger.onPageLoaded(
    () => setTimeout(() => {
        logger.trackPageview();
    })
);
