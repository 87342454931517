/**
 * Diese Datei ist dazu gedacht via Import-Anweisung (und damit dynamisch/asynchron) nachgeladen zu werden
 *
 * <script type="module">
 *   import {ActivityLogger} from "/commonSources/js/activityLogger.js";
 *   const logger = new ActivityLogger({
 *       ajaxUrlPageview: '/ajax/logPageview.php',
 *       ajaxUrlEvent: '/ajax/logEvent.php',
 *   });
 *   logger.logPageview();
 * </script>
 *
 */
class ActivityLogger {
	ajaxUrlPageview: string;
	ajaxUrlEvent: string;
	ajaxUrlLink: string;

	/**
	 * Ersatz für URL.canParse(), da es nicht von Chrome und Edge unterstützt
	 * wird (09.08.2023):
	 *
	 *  - https://caniuse.com/mdn-api_url_canparse_static
	 */
	static tryURL(url, base=undefined) {
		let ret;
        try {
            ret = new URL(url, base);
        }
        catch(err) { }

        return ret || false;
	}

	static ensureAbsoluteUrl(url) {
		let urlObj = this.tryURL(url);
        if (urlObj === false) {
            urlObj = this.tryURL(url, location.origin);
        }
        if (urlObj === false) {
            urlObj = new URL('/ks-invalid-url', location.origin);
        }

		return urlObj.href;
	}

    constructor({ajaxUrlPageview, ajaxUrlEvent, ajaxUrlLink}) {
        this.ajaxUrlPageview = ajaxUrlPageview;
        this.ajaxUrlEvent = ajaxUrlEvent || '';
        this.ajaxUrlLink = ajaxUrlLink || '';
    }

    trackPageview(url=null, title=null) {
        const formData = new FormData();
		formData.set('url', ActivityLogger.ensureAbsoluteUrl(url || location.href));
        formData.set('referrer', document.referrer);
        formData.set('title', title || document.title);
		formData.set('res-width', screen.width.toString());
		formData.set('res-height', screen.height.toString());
		formData.set('performance', JSON.stringify(ActivityLogger.pagePerformance()));
		const promise = fetch(this.ajaxUrlPageview, {
			method: 'POST',
			body: formData,
		})
		.then(res => {
            // damit die Antwort im Netzwerk-Tab der DevTools sichtbar ist.
			return res.text();
		});

        return promise;
    }

    trackEvent(category, action, name, val=null) {
        const formData = new FormData();
		formData.set('category', category);
		formData.set('action', action);
		formData.set('name', name);
		formData.set('val', val);
		formData.set('res-width', screen.width.toString());
		formData.set('res-height', screen.height.toString());

		if (!this.ajaxUrlEvent) {
			console.log('activity: leere url');
			return;
		}
		const promise = fetch(this.ajaxUrlEvent, {
			method: 'POST',
			body: formData,
		})
		.then(res => {
            // damit die Antwort im Netzwerk-Tab der DevTools sichtbar ist.
			return res.text();
		});

        return promise;
    }

	trackLink(url, type) {
        const formData = new FormData();
		formData.set('linkUrl', ActivityLogger.ensureAbsoluteUrl(url) || 'invalid-url');
		formData.set('linkType', type);
		formData.set('res-width', screen.width.toString());
		formData.set('res-height', screen.height.toString());
		if (!this.ajaxUrlLink) {
			console.log('activity: leere url');
			return;
		}
		const promise = fetch(this.ajaxUrlLink, {
			method: 'POST',
			body: formData,
		})
		.then(res => {
            // damit die Antwort im Netzwerk-Tab der DevTools sichtbar ist.
			return res.text();
		});

        return promise;
    }

	static pagePerformance() {
		let performanceData = null;
		try {
			performanceData = window.performance.getEntriesByType('navigation')[0];
		} catch (error) {
			console.error(error);
		}

		if (performanceData === null) {
			return {};
		}

		const timings = {};

		if (performanceData.connectEnd && performanceData.fetchStart) {
			if (performanceData.connectEnd < performanceData.fetchStart) {
				return {};
			}
			timings['network'] = Math.round(performanceData.connectEnd - performanceData.fetchStart);
		}

		if (performanceData.responseStart && performanceData.requestStart) {
			if (performanceData.responseStart < performanceData.requestStart) {
				return {};
			}
			timings['server'] = Math.round(performanceData.responseStart - performanceData.requestStart);
		}

		if (performanceData.responseStart && performanceData.responseEnd) {
			if (performanceData.responseEnd < performanceData.responseStart) {
				return {};
			}
			timings['transfer'] = Math.round(performanceData.responseEnd - performanceData.responseStart);
		}

		if (performanceData.domInteractive && performanceData.responseEnd) {
			if (performanceData.domInteractive < performanceData.responseEnd) {
				return {};
			}
			timings['domProcessing'] = Math.round(performanceData.domInteractive - performanceData.responseEnd);
		}

		if (performanceData.domComplete && performanceData.domInteractive) {
			if (performanceData.domComplete < performanceData.domInteractive) {
				return {};
			}
			timings['domCompletion'] = Math.round(performanceData.domComplete - performanceData.domInteractive);
		}

		if (performanceData.loadEventEnd && performanceData.loadEventStart) {
			if (performanceData.loadEventEnd < performanceData.loadEventStart) {
				return {};
			}

			timings['onload'] = Math.round(performanceData.loadEventEnd - performanceData.loadEventStart);
		}

		return timings;
	}

	static onPageLoaded(fn) {
    	// zuverlässiger als if (readyState === 'complete')
    	var navPerf = window.performance.getEntriesByType("navigation");
    	if (navPerf.length && (navPerf[0] as PerformanceNavigationTiming).loadEventEnd > 0) {
			fn();
			return;
		}
		window.addEventListener('load', fn);
	}
}

export { ActivityLogger };